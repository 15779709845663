<mat-card class="wrapper margin-5">
    <div>
        <app-search-ui></app-search-ui>
    </div>
</mat-card>
<mat-card class="search-fitting-mat-card wrapper tableCard margin-5">
    <mat-card-subtitle>Fittings</mat-card-subtitle>
    <!-- <app-generic-table class="main-page" [resetTableSubject]="resetFittingsTableSubject.asObservable()" [sharedDataService]="fittingDataService" (contextMenuClick)=onContextMenuClick($event)></app-generic-table> -->
    <app-data-table-multiple-selection [enableDropdown]="false" [downloadOption]="false" [uploadOption]="false" [showDropdown]="false" [hasFilter]="true" class="search-content-page" [tableData]="" [sharedDataService]="fittingDataService" (contextMenuClick)=onContextMenuClick($event)>
    </app-data-table-multiple-selection>
    <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
    </div>
    <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-element="element">
            <button *ngIf="IsContinued(element)" mat-menu-item (click)="onAddAction(element)">
        Add New Sizes
      </button>
            <button *ngIf="IsContinued(element)" mat-menu-item (click)="onUpdateAction(element)">
        Update Fitting Data
      </button>
            <button *ngIf="IsContinued(element)" mat-menu-item (click)="onDeleteAction(element)">
        Discontinue Sizes
      </button>
            <button mat-menu-item *ngIf="!IsContinued(element)" (click)="onChangeFittingStateActionToContinue(element)">
        Change state to Continue
      </button>
            <button mat-menu-item *ngIf="IsContinued(element)" (click)="onChangeFittingStateActionToDiscontinue(element)">
        Change state to Discontinue
      </button>
        </ng-template>
    </mat-menu>

</mat-card>