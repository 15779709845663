<mat-card-title style="color : #faa200;">{{title}}</mat-card-title>

<div class="search-container">
    <div>
        <div class="toggle-button-label">Select units</div>
        <mat-slide-toggle [disabled]="disabledUnits" [checked]="checkSelectedUnit()" (change)="setContentUnit($event)">Imperial/Metric
        </mat-slide-toggle>
    </div>

    <div>
        <div class="toggle-button-label">Search by</div>
        <mat-slide-toggle [checked]="checkSelectedSearchType()" (change)="setSearchType($event)">Keyword/Manufacturer
        </mat-slide-toggle>
    </div>

    <mat-form-field class="mat-field">
        <input matInput (click)="clearInputFilds()" [formControl]="searchFormControl" placeholder="Keyword" type="text" [(ngModel)]="searchText" autocomplete="off" />
    </mat-form-field>

    <mat-form-field class="mat-field">
        <input matInput placeholder="Manufacturer" (click)="clearManufacturer()" [formControl]="manufNameFormControl" [matAutocomplete]="autoManufacturer" />
        <mat-autocomplete #autoManufacturer="matAutocomplete" [displayWith]="displayManufName" (optionSelected)="selectManufacturer($event)">
            <mat-option *ngFor="let manuf of filteredManufacturers |async" [value]="manuf">
                {{ manuf.manufName }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="mat-field">
        <input matInput placeholder="ProductLines" (click)="clearProductLine()" [formControl]="productLineFormControl" [matAutocomplete]="autoProductLine" />
        <mat-autocomplete #autoProductLine="matAutocomplete" [displayWith]="displayProductLineName" (optionSelected)="selectProductLine($event)">
            <mat-option *ngFor="let productLine of filteredProductLines |async " [value]="productLine">
                {{productLine.productLine }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <div>
        <div class="toggle-button-label">Select fitting state</div>
        <mat-slide-toggle [disabled]="disabledFittingState"  [checked]="checkFittingState()" (change)="setFittingState($event)">Continue/Discontinue</mat-slide-toggle> 
       
    </div>

    <button style="margin-left: 0; margin-top: 5px;margin-bottom: 40px ;margin-right: 2px" mat-raised-button (click)="search()" [disabled]="isSearchingDisabled()">
    <em class="fa fa-search"></em>
  </button>

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
</div>