<div class="main-container full-height">
  <div>
    <mat-card class="wrapper mat-card-grow margin-5" *ngIf="!isExistingFittingSizesExtended">
        <div align="right">
            <a (click)="expandAddedFittingSizezComponent()" matTooltip="Swap verticaly">
                <em class="material-icons">swap_vert</em>
            </a>
        </div>
        <div>
            <mat-card-title style="color : #faa200;">Fitting State</mat-card-title>
            <app-generic-table #fittingsTable class="add-fitting" [sharedDataService]="genericTableDataService" [itemsPerPage]="4" [hasRowDisabledFunctionality]="false" [hasFilter]="true" (clickRow)=getExistingFittingSizes($any($event)) (contextMenuClick)=onAddContextMenuClick($event)
                (mouseover)="isFittingTable = true" [isAddedFittingSizesExpended]="isAddedFittingSizesExpended" [selectedRowCopy]="selectedRowCopy">
            </app-generic-table>
            <div *ngIf="isFittingTable" style="visibility: hidden; position: fixed" [style.left]="onAddContextMenuPosition.x" [style.top]="onAddContextMenuPosition.y" [matMenuTriggerFor]="onAddContextMenu">
            </div>
            <mat-menu #onAddContextMenu="matMenu">
                <ng-template matMenuContent let-element="element">
                    <button mat-menu-item (click)="onSendForApproveActionClick(element)">
            Send for approve
          </button>
                    <button mat-menu-item (click)="onUndoActionClick(element)">
            Undo
          </button>
                </ng-template>
            </mat-menu>
        </div>
    </mat-card>
    <mat-card class="wrapper mat-card-grow margin-5" *ngIf="!isAddedFittingSizesExpended">
        <div align="right">
            <a (click)="expandExistingFittingSizezComponent()" matTooltip="Swap verticaly">
                <em class="material-icons">swap_vert</em>
            </a>
        </div>
        <div>
            <mat-card-subtitle>Existing fitting sizes</mat-card-subtitle>
            <app-generic-table [resetTableSubject]="resetExistingTableSubject" class="fitting-status-management-fittingSize" [hasFilter]="false" [sharedDataService]="fittingSizeDataService" [hasRowDisabledFunctionality]="false" [isExistingFittingSizesExtended]="isExistingFittingSizesExtended">
            </app-generic-table>
        </div>
    </mat-card>
    </div>
</div>
