<mat-form-field *ngIf="hasFilter">
    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
</mat-form-field>
<mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
</mat-paginator>
<div [ngClass]="getClass()">
    <table summary="GenericTable" mat-table [dataSource]="dataSource" class="fixed-header-table" matSort>
        <ng-container [matColumnDef]="tableData" *ngFor="let tableData of objectKeys(columnHeader)">
            <th id="{{columnHeader[tableData]}}" mat-header-cell *matHeaderCellDef mat-sort-header><strong>
          {{columnHeader[tableData]}} </strong></th>
            <td mat-cell *matCellDef="let element">
                {{element[tableData]}}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="objectKeys(columnHeader); sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: objectKeys(columnHeader)" (contextmenu)="(hasContextMenu(row))&& onContextMenu($event, row)" [ngClass]="{'lightgrey-background': !row.isEnable && hasRowDisabledFunctionality,hovered: row.hovered, highlighted: (selectedRow===row)||(selectedRowCopy?.fittingEntryID===row?.fittingEntryID),'isDiscontinued': row.isDiscontinued,'isSentForApproval': row.isDisabled,'isSentForApprovalSelected':((selectedRow===row)||(selectedRowCopy?.fittingEntryID===row?.fittingEntryID)&&row.isDisabled) }"
                (click)="onClickRow(row)" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
            </tr>

    </table>
</div>
