<as-split direction="horizontal">
    <as-split-area class="left-area flex-left" [size]="50">
      <div>
        <mat-card class="wrapper mat-card-grow margin-5" *ngIf="!isExistingFittingSizesExtended">
            <div align="right">
                <a (click)="expandAddedFittingSizezComponent()" matTooltip="Swap verticaly">
                    <em class="material-icons">swap_vert</em>
                </a>
            </div>
            <mat-card-title style="color : #faa200;">Update Fitting Sizes</mat-card-title>
            <app-generic-table class="add-fitting" [sharedDataService]="genericTableDataService" [hasRowDisabledFunctionality]="false" [hasFilter]="true" (clickRow)=getExistingFittingSizes($any($event)) (contextMenuClick)=onAddContextMenuClick($event) (mouseover)="isFittingTable = true"
                [isAddedFittingSizesExpended]="isAddedFittingSizesExpended" [selectedRowCopy]="selectedRowCopy">
            </app-generic-table>
            <div *ngIf="isFittingTable && verifySelectedFitting() && !isFittingSentForApprove()" style="visibility: hidden; position: fixed" [style.left]="onAddContextMenuPosition.x" [style.top]="onAddContextMenuPosition.y" [matMenuTriggerFor]="onAddContextMenu">
            </div>
            <mat-menu #onAddContextMenu="matMenu">
                <ng-template matMenuContent let-element="element">
                    <button mat-menu-item (click)="onUndoActionClick(element)">
            Undo
          </button>
                </ng-template>
            </mat-menu>
        </mat-card>
        <mat-card class="wrapper mat-card-grow margin-5" *ngIf="!isAddedFittingSizesExpended">
            <div align="right">
                <a (click)="expandExistingFittingSizezComponent()" matTooltip="Swap verticaly">
                    <em class="material-icons">swap_vert</em>
                </a>
            </div>
            <mat-card-subtitle>Existing fitting sizes</mat-card-subtitle>
            <app-generic-table class="add-fitting-fittingSize" [resetTableSubject]="resetExistingTableSubject" [hasFilter]="false" [sharedDataService]="fittingSizeDataService" [hasRowDisabledFunctionality]="false" (contextMenuClick)=onUpdateContextMenuClick($event)
                (mouseover)="isFittingTable = false" [isExistingFittingSizesExtended]="isExistingFittingSizesExtended">
            </app-generic-table>
            <div *ngIf="!isFittingTable" style="visibility: hidden; position: fixed" [style.left]="onUpdateContextMenuPosition.x" [style.top]="onUpdateContextMenuPosition.y" [matMenuTriggerFor]="onUpdateContextMenu">
            </div>
            <mat-menu #onUpdateContextMenu="matMenu">
                <ng-template matMenuContent let-element="element">
                    <button mat-menu-item (click)="onUpdateAction(element)">
            Update
          </button>
                </ng-template>
            </mat-menu>
        </mat-card>
      </div>
    </as-split-area>
    <as-split-area class="right-area" [size]="50">
        <as-split direction="vertical">
            <mat-card class="wrapper mat-card-grow margin-5">
                <div>
                    <mat-card-subtitle>Updated Fitting Sizes</mat-card-subtitle>
                    <div class="expand-newfittings-table-content">
                        <div *ngIf="getFittingType()===2 || getFittingType()=== 4">
                            <app-piping-plumbing-fitting-size [showAddFittingButton]="false" [partialSaveActionType]="getPartialSaveActionType()" [sendForAprovalActionType]="getSentForAproveActionType()" [deleteActionType]="getDeleteActionType()">
                            </app-piping-plumbing-fitting-size>
                        </div>
                        <div *ngIf="getFittingType()===5">
                            <app-sheet-metal-fitting-size [showAddFittingButton]="false" [partialSaveActionType]="getPartialSaveActionType()" [sendForAprovalActionType]="getSentForAproveActionType()" [deleteActionType]="getDeleteActionType()">
                            </app-sheet-metal-fitting-size>
                        </div>
                        <div *ngIf="getFittingType()===9">
                            <app-cable-trays [showAddFittingButton]="false" [partialSaveActionType]="getPartialSaveActionType()" [sendForAprovalActionType]="getSentForAproveActionType()" [deleteActionType]="getDeleteActionType()">
                            </app-cable-trays>
                        </div>
                    </div>
                </div>
            </mat-card>
        </as-split>
    </as-split-area>
</as-split>
