/* istanbul ignore file */
import { Subject, Observable, BehaviorSubject, merge } from 'rxjs';
import { Directive, OnInit, OnDestroy, HostListener, HostBinding, Input, ElementRef, OnChanges } from '@angular/core';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { shareReplay, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[shiftClickSource]'
})

export class ShiftClickDirective implements OnChanges, OnInit, OnDestroy {

  constructor(private host: ElementRef) { }
  finishHim = new Subject<void>();
  lastItem: any;
  lastShiftSelection: any[];

  alwaysTheRightSourceArray$: Observable<any>;

  source: any[];
  shiftHolding$ = new BehaviorSubject<boolean>(false);

  click$ = new Subject<any>();

  recordSelectionChange$: Observable<SelectionChange<any>>;

  @HostBinding('style.user-select')
  userSelect = 'unset';
  @HostListener('document:keydown.shift', ['$event'])
  shiftDown(_) {
    this.userSelect = 'none';
    this.shiftHolding$.next(true);
  }
  @HostListener('document:keyup.shift', ['$event'])
  shiftUp(event: KeyboardEvent) {
    this.userSelect = 'unset';
    this.shiftHolding$.next(false);
  }

  @Input('shiftClickSource') dataSource: MatTableDataSource<any>;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('shiftClickSourceId') idArr: string[];
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('shiftClickSelectModel') selection: SelectionModel<any>;

  ngOnChanges(changes: any): void {
    this.source = this.setDataSource(changes.dataSource.currentValue);
  }
  ngOnInit() {

    // data can change order (sorting) and can be reduced (filtering)
    this.alwaysTheRightSourceArray$ = this.dataSource.connect()
      .pipe(
        tap(_ => {
          this.source = this.setDataSource(this.dataSource);
        })
      );

    // lets record the last change of
    this.recordSelectionChange$ = this.selection.changed.pipe(
      shareReplay(1)
    )

    // clicks on tbody mean that we need to do something
    this.host.nativeElement.childNodes[1].addEventListener("click", function () {
      this.click$.next();
    }.bind(this));

    const reactOnClickOnTbody$ =
      this.click$.pipe(
        withLatestFrom(this.shiftHolding$.asObservable()),
        withLatestFrom(this.recordSelectionChange$, (arr, c): [SelectionChange<any>, boolean] => [c, arr[1]]),
        tap(arr => {
          const v = arr[0];
          const sh = arr[1];
          const ans = [...v.added, ...v.removed][0];
          if (sh && this.lastItem) {
            this.onTbodyClicked(this.lastItem, ans);
          } else {
            this.lastItem = ans;
            this.lastShiftSelection = undefined;
            // console.log('clear');
          }
        }),
      );

    merge(
      reactOnClickOnTbody$,
      this.alwaysTheRightSourceArray$
    ).pipe(takeUntil(this.finishHim.asObservable()))
      .subscribe();
  }
  onTbodyClicked(from: object, to: object) {

    // console.log('onTbodyClickedRuns');
    const fIdx = this.getIndexBasedOnData(from);
    const tIdx = this.getIndexBasedOnData(to);

    let ans;
    let ans_last;
    if (fIdx > tIdx) {
      ans = this.source.slice(tIdx, fIdx);
    } else {
      // console.log('seconds index case');
      ans = this.source.slice(fIdx + 1, tIdx + 1);

    }

    if (this.lastShiftSelection) {
      this.selection['_emitChanges'] = false;
      this.selection.deselect(...this.lastShiftSelection);
      this.selection['_emitChanges'] = true;
    }

    this.lastShiftSelection = [...ans];

    if (fIdx > tIdx) {
      ans_last = ans.shift();
    } else {
      ans_last = ans.pop();
    }


    const cond = ans.every(el => this.selection.isSelected(el)) && !this.selection.isSelected(ans_last);

    if (cond) {

      this.selection['_emitChanges'] = false;
      this.selection.deselect(...ans);
      this.selection['_emitChanges'] = true;
    } else {
      // console.log('select')
      this.selection['_emitChanges'] = false;
      this.selection.select(...ans, ans_last);
      this.selection['_emitChanges'] = true;
    }

  }

  private setDataSource(data: MatTableDataSource<any>) {
    return data
      .sortData(
        this.dataSource.filteredData,
        this.dataSource.sort
      );
  }

  private getIndexBasedOnData(row, source = this.source): number {

    let ind: number;

    // eslint-disable-next-line prefer-const
    ind = source.findIndex(_d => Object.keys(row).every(k => _d[k] === row[k]));


    return ind;
  }

  ngOnDestroy() {
    this.finishHim.next();
    this.finishHim.complete();
  }
}
