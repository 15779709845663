<mat-card class="wrapper margin-5">
    <div>
        <app-search-content [columnHeader]="columnHeader" [title]="pageTitle"></app-search-content>
    </div>
</mat-card>
<mat-card class="search-fitting-mat-card wrapper tableCard margin-5">

    <mat-card-subtitle>Fittings</mat-card-subtitle>

    <app-data-table-multiple-selection [showDropdown]="true" [downloadOption]="true"  [uploadOption]="false" [hasFilter]="true" class="search-content-page" [tableData]="" [sharedDataService]="contentItemsManagementService">
    </app-data-table-multiple-selection>

</mat-card>
