<mat-toolbar class="mat-primary" color="primary">
    <mat-toolbar-row>

        <div>
            <button class="margin color align-left" mat-icon-button (click)="toggleSideNav()">
        <mat-icon><em class="ticons ticons-menu"></em></mat-icon>
      </button>
            <img src="/assets/Image/jira-logo-scaled.png" alt="" class="grow margin logo">
        </div>


        <div>

            <mat-icon class="icon-align"><em class="ticons ticons-user"></em></mat-icon>
            <span>Role: {{ userRights }}</span>
        </div>
        <div>
            <mat-icon class="icon-align"><em class=" ticons ticons-email"></em></mat-icon>
            <span>Email: {{ userEmail }}</span>
        </div>
        <div class="margin">

            <button mat-button class="margin color" (click)="logout()">Logout</button>

        </div>
    </mat-toolbar-row>
</mat-toolbar>