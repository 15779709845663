import { filter, map, Observable } from "rxjs";
import { IPaginationService } from "../models/interfaces/IPaginationService";
import { RequestBuilder } from "api/request-builder";
import { StrictHttpResponse } from "api/strict-http-response";
import { BaseService } from "api/base-service";
import { ApiConfiguration } from "api/api-configuration";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })

export class PaginationService extends BaseService  implements IPaginationService {
   
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
      }
 
 
    private queryParams: any;
    private keyword: string;
    private manufacturer: string | null;
    private endpointUrl: string | null;
   
    gePaginatedData<T>(pageNumber?: number, pageSize? :number): Observable<any> {
        return this.gePaginatedData$Response(pageNumber, pageSize).pipe(
          map((r: StrictHttpResponse<T>) => r.body as any)
        );
      }
    
      gePaginatedData$Response(pageNumber?: number, pageSize? :number): Observable<any> {
        const rb = new RequestBuilder(
          this.rootUrl,
          this.endpointUrl,
          'get'
        );
        if (this.queryParams) {
          Object.keys(this.queryParams).forEach(key => {
            rb.query(key, this.queryParams[key]);
          });

          rb.query('pageNumber', pageNumber);
          rb.query('pageSize', pageSize);
        }
    
        return this.http
          .request(
            rb.build({
              responseType: 'json',
              accept: 'application/json',
            })
          )
          .pipe(
            filter((r: any) => r instanceof HttpResponse),
            map((r: HttpResponse<any>) => {
              return r as any;
            })
          );
      }
    
    
    setQueryParams(queryParams?: any): void {
        this.queryParams = queryParams
    }

    setEnpointUrl(endpointUrl: string): void {
      this.endpointUrl = endpointUrl;
    }

    setKeywordAndManufacturer(keyword: string, manufacturer: string): void {
      this.keyword = keyword;
      this.manufacturer = manufacturer;
    }

    getKeywordAndManufacturer(): { keyword: string; manufacturer: string; } {
      return { keyword: this.keyword, manufacturer: this.manufacturer} as any;
    }


}