/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FittingDto } from '../models/fitting-dto';
import { FittingUnitDto } from '../models/fitting-unit-dto';
import { ManufacturerDto } from '../models/manufacturer-dto';
import { ManufacturerProductLineDto } from '../models/manufacturer-product-line-dto';

@Injectable({
  providedIn: 'root',
})
export class SearchService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFittingUnits
   */
  static readonly GetFittingUnitsPath = '/api/search/GetFittingUnits';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFittingUnits()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFittingUnits$Response(params?: {

  }): Observable<StrictHttpResponse<Array<FittingUnitDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchService.GetFittingUnitsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FittingUnitDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFittingUnits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFittingUnits(params?: {

  }): Observable<Array<FittingUnitDto>> {

    return this.getFittingUnits$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FittingUnitDto>>) => r.body as Array<FittingUnitDto>)
    );
  }

  /**
   * Path part for operation getManufacturers
   */
  static readonly GetManufacturersPath = '/api/search/GetManufacturers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManufacturers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManufacturers$Response(params?: {
    unitId?: number;

  }): Observable<StrictHttpResponse<Array<ManufacturerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchService.GetManufacturersPath, 'get');
    if (params) {

      rb.query('unitId', params.unitId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ManufacturerDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManufacturers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManufacturers(params?: {
    unitId?: number;

  }): Observable<Array<ManufacturerDto>> {

    return this.getManufacturers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ManufacturerDto>>) => r.body as Array<ManufacturerDto>)
    );
  }

  /**
   * Path part for operation getProductLines
   */
  static readonly GetProductLinesPath = '/api/search/GetProductLines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductLines()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductLines$Response(params?: {
    unitId?: number;
    manufacturerId?: number;

  }): Observable<StrictHttpResponse<Array<ManufacturerProductLineDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchService.GetProductLinesPath, 'get');
    if (params) {

      rb.query('unitId', params.unitId);
      rb.query('manufacturerId', params.manufacturerId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ManufacturerProductLineDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProductLines$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductLines(params?: {
    unitId?: number;
    manufacturerId?: number;

  }): Observable<Array<ManufacturerProductLineDto>> {

    return this.getProductLines$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ManufacturerProductLineDto>>) => r.body as Array<ManufacturerProductLineDto>)
    );
  }

  /**
   * Path part for operation getFittings
   */
  static readonly GetFittingsPath = '/api/search/GetFittings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFittings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFittings$Response(params?: {
    unitId?: number;
    isContinued?: boolean;
    productLineId?: number;

  }): Observable<StrictHttpResponse<Array<FittingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchService.GetFittingsPath, 'get');
    if (params) {

      rb.query('unitId', params.unitId);
      rb.query('isContinued', params.isContinued);
      rb.query('productLineId', params.productLineId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FittingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFittings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFittings(params?: {
    unitId?: number;
    isContinued?: boolean;
    productLineId?: number;

  }): Observable<Array<FittingDto>> {

    return this.getFittings$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FittingDto>>) => r.body as Array<FittingDto>)
    );
  }

  /**
   * Path part for operation addAssignedActionForSelectedFitting
   */
  static readonly AddAssignedActionForSelectedFittingPath = '/api/search/AddAssignedActionForSelectedFitting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAssignedActionForSelectedFitting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAssignedActionForSelectedFitting$Response(params?: {
      body?: null | Array<FittingDto>
  }): Observable<StrictHttpResponse<Array<FittingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchService.AddAssignedActionForSelectedFittingPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FittingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addAssignedActionForSelectedFitting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAssignedActionForSelectedFitting(params?: {
      body?: null | Array<FittingDto>
  }): Observable<Array<FittingDto>> {

    return this.addAssignedActionForSelectedFitting$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FittingDto>>) => r.body as Array<FittingDto>)
    );
  }

}
