<mat-card-title style="color : #faa200;">Manage Fittings</mat-card-title>
<div class="search-container">
    <div>
        <mat-form-field class="mat-field">
            <input matInput placeholder="Units" (click)="clearUnits()" [formControl]="unitsFormControl" [matAutocomplete]="autoUnits" />
            <mat-autocomplete #autoUnits="matAutocomplete" [displayWith]="displayUnitName" (optionSelected)="selectUnits($event)">
                <mat-option *ngFor="let unit of filteredUnits | async" [value]="unit">
                    {{ unit.unitsName }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field class="mat-field">
            <input matInput placeholder="Manufacturers" (click)="clearManufacturer()" [formControl]="manufacturerFormControl" [matAutocomplete]="autoManufacturer" />
            <mat-autocomplete #autoManufacturer="matAutocomplete" [displayWith]="displayManufacturerName" (optionSelected)="selectManufacturer($event)">
                <mat-option *ngFor="let manufacturer of filteredManufacturers |async" [value]="manufacturer">
                    {{ manufacturer.manufName }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field class="mat-field">
            <input matInput placeholder="Product Lines" (click)="clearProductLine()" [formControl]="productLineFormControl" [matAutocomplete]="autoProductLine" />
            <mat-autocomplete #autoProductLine="matAutocomplete" [displayWith]="displayProductLineName" (optionSelected)="selectProductLine($event)">
                <mat-option *ngFor="let productLine of filteredProductLines |async " [value]="productLine" matTooltip="{{productLine.productLine }}">
                    {{productLine.productLine }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div>
        <div class="toggle-button-label">Select fitting state</div>
        <mat-slide-toggle [checked]="checkFittingState()" [disabled]="checkProductLine()" (change)="setFittingState($event)">Continue/Discontinue</mat-slide-toggle>
    </div>
    <div>
        <span class="subbtn">
      <button style="margin-top: 6px" mat-raised-button [disabled]="isSearchButtonDisabled()"
        (click)="searchProductDetails()">
        <em class="fa fa-search"></em>
      </button>
      <link rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
    </span>
    </div>
</div>