/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ContentItemDto } from '../models/content-item-dto';
import { BuildableContentItemDto } from '../models/buildable-content-item-dto';

@Injectable({
  providedIn: 'root',
})
export class SearchContentService
  extends BaseService
{
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getContentItems
   */
  static readonly GetContentItemsPath = '/api/searchContent/GetContentItems';
  static readonly GetBuidlableContentItemsPath =
    '/api/searchContent/BuildableContentProducts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContentItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentItems$Response(params?: {
    searchWords?: null | string;
    manufacturerId?: null | number;
    manufProductLineId?: null | number;
    unitId?: number;
    discontinued: boolean;
  }): Observable<StrictHttpResponse<Array<ContentItemDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SearchContentService.GetContentItemsPath,
      'get'
    );
    if (params) {
      rb.query('searchWords', params.searchWords);
      rb.query('manufacturerID', params.manufacturerId);
      rb.query('manufProductLineId', params.manufProductLineId);
      rb.query('unitID', params.unitId);
      rb.query('discontinued', params.discontinued);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ContentItemDto>>;
        })
      );
  }

  getBuildableContentItems$Response(params?: {
    filterText?: null | string;
    manufacturerName?: null | string;
    pageNumber?: null | number;
    pageSize?: null | number;
  }): Observable<StrictHttpResponse<BuildableContentItemDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SearchContentService.GetBuidlableContentItemsPath,
      'get'
    );
    if (params) {
      rb.query('filterText', params.filterText);
      rb.query('manufacturerName', params.manufacturerName);
      rb.query('pageNumber', params.pageNumber);
      rb.query('pageSize', params.pageSize);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<BuildableContentItemDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getContentItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentItems(params?: {
    searchWords?: null | string;
    manufacturerId?: null | number;
    manufProductLineId?: null | number;
    unitId?: number;
    discontinued: boolean;
  }): Observable<Array<ContentItemDto>> {
    return this.getContentItems$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<ContentItemDto>>) =>
          r.body as Array<ContentItemDto>
      )
    );
  }

  getBuildableContentItems(params?: {
    filterText?: null | string;
    manufacturerName?: null | string;
    pageNumber?: null | number;
    pageSize?: null | number;
  }): Observable<BuildableContentItemDto> {
    return this.getBuildableContentItems$Response(params).pipe(
      map(
        (r: StrictHttpResponse<BuildableContentItemDto>) =>
          r.body as BuildableContentItemDto
      )
    );
  }

  /**
   * Path part for operation getBlobFolderNamesAsync
   */
  static readonly GetBlobFolderNamesAsyncPath =
    '/api/searchContent/GetBlobFolderNames';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBlobFolderNamesAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlobFolderNamesAsync$Response(params?: {
    containerName?: null | string;
  }): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SearchContentService.GetBlobFolderNamesAsyncPath,
      'get'
    );
    if (params) {
      rb.query('containerName', params.containerName);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<string>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBlobFolderNamesAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlobFolderNamesAsync(params?: {
    containerName?: null | string;
  }): Observable<Array<string>> {
    return this.getBlobFolderNamesAsync$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }
}
