<mat-sidenav-container class="standardBackgroundColor">

    <mat-sidenav #sidenav mode="side" class="nav-bar" [(opened)]="opened">
        <mat-nav-list>
            <mat-list-item *ngFor="let page of userPages " routerLink="{{routersPage[page.pageTitle]}}" routerLinkActive="selected-page">
                <mat-icon aria-hidden="false" aria-label="Example home icon" title="{{page.pageTitle}}">
                    <em [ngClass]=getIcon(page.id)></em>
                </mat-icon>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="main-grid">
        <router-outlet>
            <div class="loading-shade" *ngIf="showLoader">
                <mat-spinner class="d-flex justify-content-center" [diameter]="100"></mat-spinner>
            </div>
        </router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
