/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ActionPageEnumDto } from '../models/action-page-enum-dto';
import { ActionTypeEnumDto } from '../models/action-type-enum-dto';
import { CableTraySizeDto } from '../models/cable-tray-size-dto';
import { CableTraySizeDtoCableTrayFittingSizeDtoPartialSavedFittingSizesDto } from '../models/cable-tray-size-dto-cable-tray-fitting-size-dto-partial-saved-fitting-sizes-dto';
import { CreateCableTrayFittingSizeInfoDto } from '../models/create-cable-tray-fitting-size-info-dto';
import { CreateElectricalFittingSizeInfoDto } from '../models/create-electrical-fitting-size-info-dto';
import { CreatePipeFittingSizeInfoDto } from '../models/create-pipe-fitting-size-info-dto';
import { CreateSheetMetalFittingSizeInfoDto } from '../models/create-sheet-metal-fitting-size-info-dto';
import { ElectricalFittingSizeDto } from '../models/electrical-fitting-size-dto';
import { ElectricalSizeDto } from '../models/electrical-size-dto';
import { ElectricalSizeDtoElectricalFittingSizeDtoPartialSavedFittingSizesDto } from '../models/electrical-size-dto-electrical-fitting-size-dto-partial-saved-fitting-sizes-dto';
import { FittingDto } from '../models/fitting-dto';
import { PipeFittingSizeDto } from '../models/pipe-fitting-size-dto';
import { PipeSizeDto } from '../models/pipe-size-dto';
import { PipeSizeDtoPipeFittingSizeDtoPartialSavedFittingSizesDto } from '../models/pipe-size-dto-pipe-fitting-size-dto-partial-saved-fitting-sizes-dto';
import { SheetMetalFittingSizeDto } from '../models/sheet-metal-fitting-size-dto';
import { SheetMetalSizeDto } from '../models/sheet-metal-size-dto';
import { SheetMetalSizeDtoSheetMetalFittingSizeDtoPartialSavedFittingSizesDto } from '../models/sheet-metal-size-dto-sheet-metal-fitting-size-dto-partial-saved-fitting-sizes-dto';

@Injectable({
  providedIn: 'root',
})
export class FittingsManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFittingsByAssignedAction
   */
  static readonly GetFittingsByAssignedActionPath = '/api/manageFittings/GetFittingsByAssignedAction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFittingsByAssignedAction()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFittingsByAssignedAction$Response(params?: {
    userId?: string;
    actionPageDto?: ActionPageEnumDto;

  }): Observable<StrictHttpResponse<Array<FittingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetFittingsByAssignedActionPath, 'get');
    if (params) {

      rb.query('userId', params.userId);
      rb.query('actionPageDto', params.actionPageDto);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FittingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFittingsByAssignedAction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFittingsByAssignedAction(params?: {
    userId?: string;
    actionPageDto?: ActionPageEnumDto;

  }): Observable<Array<FittingDto>> {

    return this.getFittingsByAssignedAction$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FittingDto>>) => r.body as Array<FittingDto>)
    );
  }

  /**
   * Path part for operation getRecordsForApproval
   */
  static readonly GetRecordsForApprovalPath = '/api/manageFittings/GetRecordsForApproval';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecordsForApproval()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecordsForApproval$Response(params?: {
    actionPageDto?: ActionPageEnumDto;

  }): Observable<StrictHttpResponse<Array<FittingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetRecordsForApprovalPath, 'get');
    if (params) {

      rb.query('actionPageDto', params.actionPageDto);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FittingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecordsForApproval$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecordsForApproval(params?: {
    actionPageDto?: ActionPageEnumDto;

  }): Observable<Array<FittingDto>> {

    return this.getRecordsForApproval$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FittingDto>>) => r.body as Array<FittingDto>)
    );
  }

  /**
   * Path part for operation getCableTrayFittingSize
   */
  static readonly GetCableTrayFittingSizePath = '/api/manageFittings/GetCableTrayFittingSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCableTrayFittingSize()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCableTrayFittingSize$Response(params?: {
    fittingDefinitionId?: number;

  }): Observable<StrictHttpResponse<Array<CableTraySizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetCableTrayFittingSizePath, 'get');
    if (params) {

      rb.query('fittingDefinitionID', params.fittingDefinitionId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CableTraySizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCableTrayFittingSize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCableTrayFittingSize(params?: {
    fittingDefinitionId?: number;

  }): Observable<Array<CableTraySizeDto>> {

    return this.getCableTrayFittingSize$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CableTraySizeDto>>) => r.body as Array<CableTraySizeDto>)
    );
  }

  /**
   * Path part for operation getElectricalFittingSize
   */
  static readonly GetElectricalFittingSizePath = '/api/manageFittings/GetElectricalFittingSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getElectricalFittingSize()` instead.
   *
   * This method doesn't expect any request body.
   */
  getElectricalFittingSize$Response(params?: {
    fittingDefinitionId?: number;

  }): Observable<StrictHttpResponse<Array<ElectricalFittingSizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetElectricalFittingSizePath, 'get');
    if (params) {

      rb.query('fittingDefinitionID', params.fittingDefinitionId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ElectricalFittingSizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getElectricalFittingSize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getElectricalFittingSize(params?: {
    fittingDefinitionId?: number;

  }): Observable<Array<ElectricalFittingSizeDto>> {

    return this.getElectricalFittingSize$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ElectricalFittingSizeDto>>) => r.body as Array<ElectricalFittingSizeDto>)
    );
  }

  /**
   * Path part for operation getPipeFittingSize
   */
  static readonly GetPipeFittingSizePath = '/api/manageFittings/GetPipeFittingSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPipeFittingSize()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPipeFittingSize$Response(params?: {
    fittingDefinitionId?: number;

  }): Observable<StrictHttpResponse<Array<PipeFittingSizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetPipeFittingSizePath, 'get');
    if (params) {

      rb.query('fittingDefinitionID', params.fittingDefinitionId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PipeFittingSizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPipeFittingSize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPipeFittingSize(params?: {
    fittingDefinitionId?: number;

  }): Observable<Array<PipeFittingSizeDto>> {

    return this.getPipeFittingSize$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PipeFittingSizeDto>>) => r.body as Array<PipeFittingSizeDto>)
    );
  }

  /**
   * Path part for operation getSheetMetalFittingSize
   */
  static readonly GetSheetMetalFittingSizePath = '/api/manageFittings/GetSheetMetalFittingSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSheetMetalFittingSize()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSheetMetalFittingSize$Response(params?: {
    fittingDefinitionId?: number;

  }): Observable<StrictHttpResponse<Array<SheetMetalFittingSizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetSheetMetalFittingSizePath, 'get');
    if (params) {

      rb.query('fittingDefinitionID', params.fittingDefinitionId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SheetMetalFittingSizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSheetMetalFittingSize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSheetMetalFittingSize(params?: {
    fittingDefinitionId?: number;

  }): Observable<Array<SheetMetalFittingSizeDto>> {

    return this.getSheetMetalFittingSize$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SheetMetalFittingSizeDto>>) => r.body as Array<SheetMetalFittingSizeDto>)
    );
  }

  /**
   * Path part for operation getPartialSizeCableTrayFittingSize
   */
  static readonly GetPartialSizeCableTrayFittingSizePath = '/api/manageFittings/GetPartialSizeCableTrayFittingSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartialSizeCableTrayFittingSize()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartialSizeCableTrayFittingSize$Response(params?: {
    bdToolId?: number;
    fittingTypeId?: number;
    productDisciplineId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    isMetric?: boolean;

  }): Observable<StrictHttpResponse<CableTraySizeDtoCableTrayFittingSizeDtoPartialSavedFittingSizesDto>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetPartialSizeCableTrayFittingSizePath, 'get');
    if (params) {

      rb.query('bdToolID', params.bdToolId);
      rb.query('fittingTypeId', params.fittingTypeId);
      rb.query('productDisciplineId', params.productDisciplineId);
      rb.query('actionTypeEnumDto', params.actionTypeEnumDto);
      rb.query('isMetric', params.isMetric);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CableTraySizeDtoCableTrayFittingSizeDtoPartialSavedFittingSizesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartialSizeCableTrayFittingSize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartialSizeCableTrayFittingSize(params?: {
    bdToolId?: number;
    fittingTypeId?: number;
    productDisciplineId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    isMetric?: boolean;

  }): Observable<CableTraySizeDtoCableTrayFittingSizeDtoPartialSavedFittingSizesDto> {

    return this.getPartialSizeCableTrayFittingSize$Response(params).pipe(
      map((r: StrictHttpResponse<CableTraySizeDtoCableTrayFittingSizeDtoPartialSavedFittingSizesDto>) => r.body as CableTraySizeDtoCableTrayFittingSizeDtoPartialSavedFittingSizesDto)
    );
  }

  /**
   * Path part for operation getPartialSizeElectricalFittingSize
   */
  static readonly GetPartialSizeElectricalFittingSizePath = '/api/manageFittings/GetPartialSizeElectricalFittingSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartialSizeElectricalFittingSize()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartialSizeElectricalFittingSize$Response(params?: {
    bdToolId?: number;
    fittingTypeId?: number;
    productDisciplineId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    isMetric?: boolean;

  }): Observable<StrictHttpResponse<ElectricalSizeDtoElectricalFittingSizeDtoPartialSavedFittingSizesDto>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetPartialSizeElectricalFittingSizePath, 'get');
    if (params) {

      rb.query('bdToolID', params.bdToolId);
      rb.query('fittingTypeId', params.fittingTypeId);
      rb.query('productDisciplineId', params.productDisciplineId);
      rb.query('actionTypeEnumDto', params.actionTypeEnumDto);
      rb.query('isMetric', params.isMetric);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectricalSizeDtoElectricalFittingSizeDtoPartialSavedFittingSizesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartialSizeElectricalFittingSize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartialSizeElectricalFittingSize(params?: {
    bdToolId?: number;
    fittingTypeId?: number;
    productDisciplineId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    isMetric?: boolean;

  }): Observable<ElectricalSizeDtoElectricalFittingSizeDtoPartialSavedFittingSizesDto> {

    return this.getPartialSizeElectricalFittingSize$Response(params).pipe(
      map((r: StrictHttpResponse<ElectricalSizeDtoElectricalFittingSizeDtoPartialSavedFittingSizesDto>) => r.body as ElectricalSizeDtoElectricalFittingSizeDtoPartialSavedFittingSizesDto)
    );
  }

  /**
   * Path part for operation getPartialSizePipeFittingSize
   */
  static readonly GetPartialSizePipeFittingSizePath = '/api/manageFittings/GetPartialSizePipeFittingSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartialSizePipeFittingSize()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartialSizePipeFittingSize$Response(params?: {
    bdToolId?: number;
    fittingTypeId?: number;
    productDisciplineId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    isMetric?: boolean;

  }): Observable<StrictHttpResponse<PipeSizeDtoPipeFittingSizeDtoPartialSavedFittingSizesDto>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetPartialSizePipeFittingSizePath, 'get');
    if (params) {

      rb.query('bdToolID', params.bdToolId);
      rb.query('fittingTypeId', params.fittingTypeId);
      rb.query('productDisciplineId', params.productDisciplineId);
      rb.query('actionTypeEnumDto', params.actionTypeEnumDto);
      rb.query('isMetric', params.isMetric);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PipeSizeDtoPipeFittingSizeDtoPartialSavedFittingSizesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartialSizePipeFittingSize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartialSizePipeFittingSize(params?: {
    bdToolId?: number;
    fittingTypeId?: number;
    productDisciplineId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    isMetric?: boolean;

  }): Observable<PipeSizeDtoPipeFittingSizeDtoPartialSavedFittingSizesDto> {

    return this.getPartialSizePipeFittingSize$Response(params).pipe(
      map((r: StrictHttpResponse<PipeSizeDtoPipeFittingSizeDtoPartialSavedFittingSizesDto>) => r.body as PipeSizeDtoPipeFittingSizeDtoPartialSavedFittingSizesDto)
    );
  }

  /**
   * Path part for operation getPartialSizeSheetMetalFittingSize
   */
  static readonly GetPartialSizeSheetMetalFittingSizePath = '/api/manageFittings/GetPartialSizeSheetMetalFittingSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartialSizeSheetMetalFittingSize()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartialSizeSheetMetalFittingSize$Response(params?: {
    bdToolId?: number;
    fittingTypeId?: number;
    productDisciplineId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    isMetric?: boolean;

  }): Observable<StrictHttpResponse<SheetMetalSizeDtoSheetMetalFittingSizeDtoPartialSavedFittingSizesDto>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetPartialSizeSheetMetalFittingSizePath, 'get');
    if (params) {

      rb.query('bdToolID', params.bdToolId);
      rb.query('fittingTypeId', params.fittingTypeId);
      rb.query('productDisciplineId', params.productDisciplineId);
      rb.query('actionTypeEnumDto', params.actionTypeEnumDto);
      rb.query('isMetric', params.isMetric);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SheetMetalSizeDtoSheetMetalFittingSizeDtoPartialSavedFittingSizesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartialSizeSheetMetalFittingSize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartialSizeSheetMetalFittingSize(params?: {
    bdToolId?: number;
    fittingTypeId?: number;
    productDisciplineId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    isMetric?: boolean;

  }): Observable<SheetMetalSizeDtoSheetMetalFittingSizeDtoPartialSavedFittingSizesDto> {

    return this.getPartialSizeSheetMetalFittingSize$Response(params).pipe(
      map((r: StrictHttpResponse<SheetMetalSizeDtoSheetMetalFittingSizeDtoPartialSavedFittingSizesDto>) => r.body as SheetMetalSizeDtoSheetMetalFittingSizeDtoPartialSavedFittingSizesDto)
    );
  }

  /**
   * Path part for operation getCableTraySizes
   */
  static readonly GetCableTraySizesPath = '/api/manageFittings/GetCableTraySizes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCableTraySizes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCableTraySizes$Response(params?: {
    fittingTypeId?: number;
    isMetric?: boolean;
    productDisciplineId?: number;

  }): Observable<StrictHttpResponse<Array<CableTraySizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetCableTraySizesPath, 'get');
    if (params) {

      rb.query('fittingTypeId', params.fittingTypeId);
      rb.query('isMetric', params.isMetric);
      rb.query('productDisciplineId', params.productDisciplineId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CableTraySizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCableTraySizes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCableTraySizes(params?: {
    fittingTypeId?: number;
    isMetric?: boolean;
    productDisciplineId?: number;

  }): Observable<Array<CableTraySizeDto>> {

    return this.getCableTraySizes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CableTraySizeDto>>) => r.body as Array<CableTraySizeDto>)
    );
  }

  /**
   * Path part for operation getElectricalSizes
   */
  static readonly GetElectricalSizesPath = '/api/manageFittings/GetElectricalSizes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getElectricalSizes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getElectricalSizes$Response(params?: {
    fittingTypeId?: number;
    isMetric?: boolean;
    productDisciplineId?: number;

  }): Observable<StrictHttpResponse<Array<ElectricalSizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetElectricalSizesPath, 'get');
    if (params) {

      rb.query('fittingTypeId', params.fittingTypeId);
      rb.query('isMetric', params.isMetric);
      rb.query('productDisciplineId', params.productDisciplineId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ElectricalSizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getElectricalSizes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getElectricalSizes(params?: {
    fittingTypeId?: number;
    isMetric?: boolean;
    productDisciplineId?: number;

  }): Observable<Array<ElectricalSizeDto>> {

    return this.getElectricalSizes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ElectricalSizeDto>>) => r.body as Array<ElectricalSizeDto>)
    );
  }

  /**
   * Path part for operation getPipeSizes
   */
  static readonly GetPipeSizesPath = '/api/manageFittings/GetPipeSizes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPipeSizes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPipeSizes$Response(params?: {
    fittingTypeId?: number;
    isMetric?: boolean;
    productDisciplineId?: number;

  }): Observable<StrictHttpResponse<Array<PipeSizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetPipeSizesPath, 'get');
    if (params) {

      rb.query('fittingTypeId', params.fittingTypeId);
      rb.query('isMetric', params.isMetric);
      rb.query('productDisciplineId', params.productDisciplineId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PipeSizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPipeSizes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPipeSizes(params?: {
    fittingTypeId?: number;
    isMetric?: boolean;
    productDisciplineId?: number;

  }): Observable<Array<PipeSizeDto>> {

    return this.getPipeSizes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PipeSizeDto>>) => r.body as Array<PipeSizeDto>)
    );
  }

  /**
   * Path part for operation getSheetMetalSizes
   */
  static readonly GetSheetMetalSizesPath = '/api/manageFittings/GetSheetMetalSizes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSheetMetalSizes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSheetMetalSizes$Response(params?: {
    fittingTypeId?: number;
    isMetric?: boolean;
    productDisciplineId?: number;

  }): Observable<StrictHttpResponse<Array<SheetMetalSizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.GetSheetMetalSizesPath, 'get');
    if (params) {

      rb.query('fittingTypeId', params.fittingTypeId);
      rb.query('isMetric', params.isMetric);
      rb.query('productDisciplineId', params.productDisciplineId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SheetMetalSizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSheetMetalSizes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSheetMetalSizes(params?: {
    fittingTypeId?: number;
    isMetric?: boolean;
    productDisciplineId?: number;

  }): Observable<Array<SheetMetalSizeDto>> {

    return this.getSheetMetalSizes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SheetMetalSizeDto>>) => r.body as Array<SheetMetalSizeDto>)
    );
  }

  /**
   * Path part for operation addNewSizeForPipeFitting
   */
  static readonly AddNewSizeForPipeFittingPath = '/api/manageFittings/AddNewSizeForPipeFitting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewSizeForPipeFitting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewSizeForPipeFitting$Response(params?: {
      body?: CreatePipeFittingSizeInfoDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.AddNewSizeForPipeFittingPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewSizeForPipeFitting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewSizeForPipeFitting(params?: {
      body?: CreatePipeFittingSizeInfoDto
  }): Observable<void> {

    return this.addNewSizeForPipeFitting$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addNewSizeForElectricalFitting
   */
  static readonly AddNewSizeForElectricalFittingPath = '/api/manageFittings/AddNewSizeForElectricalFitting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewSizeForElectricalFitting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewSizeForElectricalFitting$Response(params?: {
      body?: CreateElectricalFittingSizeInfoDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.AddNewSizeForElectricalFittingPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewSizeForElectricalFitting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewSizeForElectricalFitting(params?: {
      body?: CreateElectricalFittingSizeInfoDto
  }): Observable<void> {

    return this.addNewSizeForElectricalFitting$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addNewSizeForCableTrayFitting
   */
  static readonly AddNewSizeForCableTrayFittingPath = '/api/manageFittings/AddNewSizeForCableTrayFitting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewSizeForCableTrayFitting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewSizeForCableTrayFitting$Response(params?: {
      body?: CreateCableTrayFittingSizeInfoDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.AddNewSizeForCableTrayFittingPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewSizeForCableTrayFitting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewSizeForCableTrayFitting(params?: {
      body?: CreateCableTrayFittingSizeInfoDto
  }): Observable<void> {

    return this.addNewSizeForCableTrayFitting$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addNewSizeForSheetMetalFitting
   */
  static readonly AddNewSizeForSheetMetalFittingPath = '/api/manageFittings/AddNewSizeForSheetMetalFitting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewSizeForSheetMetalFitting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewSizeForSheetMetalFitting$Response(params?: {
      body?: CreateSheetMetalFittingSizeInfoDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.AddNewSizeForSheetMetalFittingPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewSizeForSheetMetalFitting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewSizeForSheetMetalFitting(params?: {
      body?: CreateSheetMetalFittingSizeInfoDto
  }): Observable<void> {

    return this.addNewSizeForSheetMetalFitting$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteSavedRecord
   */
  static readonly DeleteSavedRecordPath = '/api/manageFittings/DeleteSavedRecord';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSavedRecord()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSavedRecord$Response(params?: {
    id?: number;
    userId?: string;
    bdToolId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    fittingEntryId?: number;
    comment?: null | string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.DeleteSavedRecordPath, 'post');
    if (params) {

      rb.query('id', params.id);
      rb.query('userId', params.userId);
      rb.query('bdToolId', params.bdToolId);
      rb.query('actionTypeEnumDto', params.actionTypeEnumDto);
      rb.query('fittingEntryId', params.fittingEntryId);
      rb.query('comment', params.comment);

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSavedRecord$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSavedRecord(params?: {
    id?: number;
    userId?: string;
    bdToolId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    fittingEntryId?: number;
    comment?: null | string;

  }): Observable<void> {

    return this.deleteSavedRecord$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateApprovalStatus
   */
  static readonly UpdateApprovalStatusPath = '/api/manageFittings/UpdateApprovalStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApprovalStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateApprovalStatus$Response(params?: {
    userId?: string;
    bdToolId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    fittingEntryId?: number;
    comment?: null | string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.UpdateApprovalStatusPath, 'put');
    if (params) {

      rb.query('userId', params.userId);
      rb.query('bdToolId', params.bdToolId);
      rb.query('actionTypeEnumDto', params.actionTypeEnumDto);
      rb.query('fittingEntryId', params.fittingEntryId);
      rb.query('comment', params.comment);

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApprovalStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateApprovalStatus(params?: {
    userId?: string;
    bdToolId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    fittingEntryId?: number;
    comment?: null | string;

  }): Observable<void> {

    return this.updateApprovalStatus$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation undoFittingRequest
   */
  static readonly UndoFittingRequestPath = '/api/manageFittings/UndoFittingRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `undoFittingRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  undoFittingRequest$Response(params?: {
    userId?: string;
    bdToolId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    fittingEntryId?: number;
    comment?: null | string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.UndoFittingRequestPath, 'put');
    if (params) {

      rb.query('userId', params.userId);
      rb.query('bdToolId', params.bdToolId);
      rb.query('actionTypeEnumDto', params.actionTypeEnumDto);
      rb.query('fittingEntryId', params.fittingEntryId);
      rb.query('comment', params.comment);

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `undoFittingRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  undoFittingRequest(params?: {
    userId?: string;
    bdToolId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    fittingEntryId?: number;
    comment?: null | string;

  }): Observable<void> {

    return this.undoFittingRequest$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateFittingStatusAsync
   */
  static readonly UpdateFittingStatusAsyncPath = '/api/manageFittings/UpdateFittingStatusAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFittingStatusAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateFittingStatusAsync$Response(params?: {
    userId?: string;
    bdToolId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    fittingEntryId?: number;
    isDiscontinue?: boolean;
    comment?: null | string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsManagementService.UpdateFittingStatusAsyncPath, 'put');
    if (params) {

      rb.query('userId', params.userId);
      rb.query('bdToolId', params.bdToolId);
      rb.query('actionTypeEnumDto', params.actionTypeEnumDto);
      rb.query('fittingEntryId', params.fittingEntryId);
      rb.query('isDiscontinue', params.isDiscontinue);
      rb.query('comment', params.comment);

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFittingStatusAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateFittingStatusAsync(params?: {
    userId?: string;
    bdToolId?: number;
    actionTypeEnumDto?: ActionTypeEnumDto;
    fittingEntryId?: number;
    isDiscontinue?: boolean;
    comment?: null | string;

  }): Observable<void> {

    return this.updateFittingStatusAsync$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
