
<link rel="stylesheet"
href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />

<mat-card class="wrapper margin-5">
    <mat-card-title style="color : #faa200;">Upload Content</mat-card-title>
</mat-card>

<mat-card class="search-fitting-mat-card wrapper tableCard margin-5">
  <mat-card-subtitle>Fittings</mat-card-subtitle>
  <app-data-table-multiple-selection [showDropdown]="false" [downloadOption]="false" [uploadOption]="true" [hasFilter]="true" class="upload-content-page" [tableData]=""
    [sharedDataService]="contentItemsManagementService">
  </app-data-table-multiple-selection>

</mat-card>

