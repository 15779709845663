<div class="container">
  <mat-form-field *ngIf="showDropdown" class="dropdown">
    <mat-label>Download as...</mat-label>
    <mat-select [formControl]="files" multiple (selectionChange)="$event ? onCheckboxAction($event) : null">
      <mat-option *ngFor="let item of filesList" [value]="item">{{item}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button *ngIf="downloadOption" mat-raised-button [disabled]="isSelected()" (click)="onDownloadClick()">
    <span class="material-icons">arrow_circle_down</span> Download
  </button>
  <button *ngIf="copyAllOption" mat-raised-button [disabled]="hasNoManufactereOrkeyword()" (click)="onCopyAllClick()">
    <span class="material-icons">copy_all</span> CopyAll
  </button>
  <button *ngIf="copyOption" mat-raised-button [disabled]="hasSelectedContentToCopy()"  (click)="onCopyClick()">
    <span class="material-icons">content_copy</span> Copy
  </button>
  <button #buttonPicker="appFolderPicker" [disabled]="isUploading()" (filesChanged)="_onFilesChanged($event)"
    *ngIf="uploadOption" [directoryPicker]="true" appFolderPicker mat-raised-button>
    Select folder
  </button>
  <button *ngIf="uploadOption" mat-raised-button [disabled]="hasFittingSelected()" (click)="onUploadClick()">
    <span class="material-icons">arrow_circle_up</span> Upload
  </button>
</div>