<div class="max-height">
    <form [formGroup]="form" id="ngForm" *ngIf="columnHeader!==undefined" autocomplete="off">
        <ng-container formArrayName="newFittingSizes">
            <mat-table [dataSource]="newFittingSizes.controls" class="fixed-header-table">
                <ng-container [matColumnDef]="tableData" *ngFor="let tableData of objectKeys(columnHeader)">
                    <mat-header-cell class="fitting-manager" *matHeaderCellDef> <strong>{{columnHeader[tableData]}}</strong>
                    </mat-header-cell>
                    <mat-cell class="fitting-manager" *matCellDef="let element " [formGroup]="element">
                        <mat-form-field floatLabel="auto" *ngIf="tableData!=='NominalSize'">
                            <input matInput (change)="onWeightValueChange(element)" placeholder={{columnHeader[tableData]}} formControlName={{tableData}} />
                            <mat-error *ngIf="element.controls[tableData].hasError('required')">
                                {{columnHeader[tableData]}} is <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="element.controls[tableData].hasError('Smaller')">
                                {{columnHeader[tableData]}} is <strong>smaller than start page</strong>
                            </mat-error>
                            <mat-error *ngIf="element.controls[tableData].hasError('pattern')">
                                Must be a positive number
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field floatLabel="auto" *ngIf="tableData==='NominalSize'">
                            <mat-select formControlName="{{tableData}}" (selectionChange)="onSizeChange(element)">
                                <mat-option *ngFor="let size of nominalSizeList " [value]="size">{{size.NominalSize}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="element.controls[tableData].hasError('required')">
                                {{columnHeader[tableData]}} is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="objectKeys(columnHeader);sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: objectKeys(columnHeader)" (contextmenu)="onContextMenuClick($event, row)">
                </mat-row>
            </mat-table>
        </ng-container>
    </form>
    <div *ngIf="!isFittingSentForApprove()" style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
    </div>
    <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-element="row">
            <button mat-menu-item (click)="onRemoveSavedRecordClick()">
        Delete
      </button>
            <button *ngIf="this.partialSaveActionType !== 'PartialSaveDelete'" mat-menu-item (click)="editSelectedField()">
        Edit
      </button>
        </ng-template>
    </mat-menu>
</div>
<div *ngIf="showButtons" class="add-fitting-size-button-group">
    <button mat-stroked-button class="add-fitting-size-button" [disabled]="isSendForApprovalDisabled()" (click)="onSendForAprovalClick()">Send for
    Approval</button>
    <button mat-stroked-button class="add-fitting-size-button" form="ngForm" [disabled]="isPartialSaveDisabled()" (click)="onPartialSaveClick()">Partial
    Save</button>
    <button mat-stroked-button *ngIf="showAddFittingButton" class="add-fitting-size-button" [disabled]="isAddNewSizeDisabled()" (click)="addFieldValue()">Add
    new size</button>
</div>