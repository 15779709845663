/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PageDto } from '../models/page-dto';
import { PageRoleRelationshipDto } from '../models/page-role-relationship-dto';
import { RoleDto } from '../models/role-dto';
import { UserDto } from '../models/user-dto';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllUsers
   */
  static readonly GetAllUsersPath = '/api/users/GetAllUsers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsers$Response(params?: {

  }): Observable<StrictHttpResponse<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetAllUsersPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsers(params?: {

  }): Observable<Array<UserDto>> {

    return this.getAllUsers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation getAllPages
   */
  static readonly GetAllPagesPath = '/api/users/GetAllPages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPages$Response(params?: {

  }): Observable<StrictHttpResponse<Array<PageDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetAllPagesPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PageDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPages(params?: {

  }): Observable<Array<PageDto>> {

    return this.getAllPages$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PageDto>>) => r.body as Array<PageDto>)
    );
  }

  /**
   * Path part for operation getPageRoleRelationship
   */
  static readonly GetPageRoleRelationshipPath = '/api/users/GetPageRoleRelationship';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPageRoleRelationship()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageRoleRelationship$Response(params?: {

  }): Observable<StrictHttpResponse<Array<PageRoleRelationshipDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetPageRoleRelationshipPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PageRoleRelationshipDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPageRoleRelationship$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageRoleRelationship(params?: {

  }): Observable<Array<PageRoleRelationshipDto>> {

    return this.getPageRoleRelationship$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PageRoleRelationshipDto>>) => r.body as Array<PageRoleRelationshipDto>)
    );
  }

  /**
   * Path part for operation getPagesByUserAsync
   */
  static readonly GetPagesByUserAsyncPath = '/api/users/GetPagesByUserAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPagesByUserAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPagesByUserAsync$Response(params?: {
    roleDto?: RoleDto;

  }): Observable<StrictHttpResponse<Array<PageDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetPagesByUserAsyncPath, 'get');
    if (params) {

      rb.query('roleDto', params.roleDto);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PageDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPagesByUserAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPagesByUserAsync(params?: {
    roleDto?: RoleDto;

  }): Observable<Array<PageDto>> {

    return this.getPagesByUserAsync$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PageDto>>) => r.body as Array<PageDto>)
    );
  }

  /**
   * Path part for operation getUserDetails
   */
  static readonly GetUserDetailsPath = '/api/users/GetUserDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserDetails$Response(params?: {
    userId?: string;

  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUserDetailsPath, 'get');
    if (params) {

      rb.query('userId', params.userId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserDetails(params?: {
    userId?: string;

  }): Observable<UserDto> {

    return this.getUserDetails$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation addNewUser
   */
  static readonly AddNewUserPath = '/api/users/AddNewUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addNewUser$Response(params?: {
      body?: UserDto
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.AddNewUserPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addNewUser(params?: {
      body?: UserDto
  }): Observable<UserDto> {

    return this.addNewUser$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation updateUserRoleAsync
   */
  static readonly UpdateUserRoleAsyncPath = '/api/users/UpdateUserRoleAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserRoleAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserRoleAsync$Response(params?: {
    role?: RoleDto;
      body?: null | Array<UserDto>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UpdateUserRoleAsyncPath, 'put');
    if (params) {

      rb.query('role', params.role);

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserRoleAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserRoleAsync(params?: {
    role?: RoleDto;
      body?: null | Array<UserDto>
  }): Observable<void> {

    return this.updateUserRoleAsync$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addNewPageRoleRelationship
   */
  static readonly AddNewPageRoleRelationshipPath = '/api/users/AddNewPageRoleRelationship';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewPageRoleRelationship()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addNewPageRoleRelationship$Response(params?: {
    role?: RoleDto;
      body?: null | Array<PageDto>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.AddNewPageRoleRelationshipPath, 'post');
    if (params) {

      rb.query('role', params.role);

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewPageRoleRelationship$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addNewPageRoleRelationship(params?: {
    role?: RoleDto;
      body?: null | Array<PageDto>
  }): Observable<void> {

    return this.addNewPageRoleRelationship$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deletePageRoleRelationship
   */
  static readonly DeletePageRoleRelationshipPath = '/api/users/DeletePageRoleRelationship';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePageRoleRelationship()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deletePageRoleRelationship$Response(params?: {
      body?: null | Array<PageRoleRelationshipDto>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.DeletePageRoleRelationshipPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePageRoleRelationship$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deletePageRoleRelationship(params?: {
      body?: null | Array<PageRoleRelationshipDto>
  }): Observable<void> {

    return this.deletePageRoleRelationship$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteUserDetailsFromDatabase
   */
  static readonly DeleteUserDetailsFromDatabasePath = '/api/users/DeleteUserDetailsFromDatabase';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserDetailsFromDatabase()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserDetailsFromDatabase$Response(params?: {
    userId?: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.DeleteUserDetailsFromDatabasePath, 'post');
    if (params) {

      rb.query('userId', params.userId);

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUserDetailsFromDatabase$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserDetailsFromDatabase(params?: {
    userId?: string;

  }): Observable<void> {

    return this.deleteUserDetailsFromDatabase$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
