<as-split direction="horizontal">
    <as-split-area class="left-area flex-left" [size]="50">
      <div>
        <mat-card class="wrapper mat-card-grow margin-5">
            <mat-card-title style="color : #faa200;">Assign Role And Rights</mat-card-title>
            <mat-form-field>
                <mat-label>Select user role</mat-label>
                <mat-select>
                    <mat-option *ngFor="let role of userRolesList" [value]="role" (click)="setSelectedRoleForAssignPage(role)">
                        {{ role}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="list-container">
                <app-data-table-multiple-selection [tableHeader]="pagesColumnHeader" [showDropdown]='false' [enableDropdown] = "false" [tableData]="pages " [hasPagiantor]="false" [hasFilter]="false" (checkBoxClick)="onAssignPagesToRoleCheckboxClick($event)" class="user-pages">
                </app-data-table-multiple-selection>
            </div>
            <div class="assign-button">
                <button (click)="onAssignPagesClick()" [disabled]="isRoleSelectedForAssignPage()">Assign pages</button>
            </div>
        </mat-card>
        <mat-card class="wrapper mat-card-grow margin-5">
            <div class="user-pages">
                <mat-card-subtitle>User pages and roles</mat-card-subtitle>
                <app-data-table-multiple-selection [tableHeader]="rightsToRolescolumnHeader" [showDropdown]='false' [enableDropdown] = "false" [tableData]="userRolesAndPages" [hasPagiantor]="false" [hasFilter]="true" (checkBoxClick)="onRevokePagesToRoleCheckboxClick($event)" class="user-pages">
                </app-data-table-multiple-selection>
            </div>
            <div class="assign-button">
                <button (click)="onRevokePagesClick()" [disabled]="hasSelectedPagesIds()">Revoke pages</button>
            </div>
        </mat-card>
        </div>
    </as-split-area>
    <as-split-area class="right-area" [size]="50">
        <as-split direction="vertical">
            <mat-card class="wrapper mat-card-grow margin-5">
                <mat-form-field>
                    <mat-label>Select user role</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let role of userRolesList" [value]="role" (click)="setSelectedRoleForAssignRole(role)">
                            {{ role }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="users-list-container">
                    <app-data-table-multiple-selection [tableHeader]="userRolesHeader" [showDropdown]='false' [enableDropdown] = "false" [tableData]="users" [hasFilter]="true" (checkBoxClick)="onUpdateUserRoleCheckboxClick($event)" class="user-settings">
                    </app-data-table-multiple-selection>
                </div>
                <div class="assign-roles-button">
                    <button (click)="onChangeRoleForUserClick()" [disabled]="isRoleSelectedForAssignRole()">Update User
            Role</button>

                </div>
            </mat-card>
        </as-split>
    </as-split-area>
</as-split>
