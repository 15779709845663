<mat-card class="wrapper margin-5">
    <div>
        <app-search-content [disabledProductLines]="true" [isBuildableContent]="true" [disabledUnits]="true"
            [disabledFittingState]="true" [columnHeader]="columnHeader" [title]="pageTitle"></app-search-content>
    </div>
</mat-card>
<mat-card class="search-fitting-mat-card wrapper tableCard margin-5">

    <mat-card-subtitle>Fittings</mat-card-subtitle>

    <app-data-table-multiple-selection [hasPaginationService]="true" [EndpointUrl]="endpointUrl"
        [paginationService]="paginationService" [copyAllOption]="true" [copyOption]="true" [showDropdown]="false"
        [downloadOption]="false" [uploadOption]="false" [hasFilter]="true" class="search-content-page"
        [sharedDataService]="contentItemsManagementService">
    </app-data-table-multiple-selection>

</mat-card>