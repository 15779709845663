<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.message}}</p>
  <mat-form-field *ngIf="data.addComment">
    <mat-label>Comment</mat-label>
    <input matInput [(ngModel)]="data.comment">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onOkClick()">Ok</button>
  <button mat-button (click)="onCancelClick()">Cancel</button>
</div>
