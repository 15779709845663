import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SearchContentService } from 'api/services';
import { GenericTableData } from 'src/app/sharedServices/generic-table-data.service';
import { PaginationService } from 'src/app/sharedServices/pagination.service';

@Component({
  selector: 'app-copy-blob-files',
  templateUrl: './copy-blob-files.component.html', // Ensure this file exists at the specified path
})
export class CopyBlobFilesComponent {
  constructor(public contentItemsManagementService: GenericTableData, public paginationService: PaginationService) {

    paginationService.setQueryParams({ pageSize: 50, pageIndex: 0 });
  }

  pageTitle = 'Copy Blob Files';
  enabledDropdown = true;
  endpointUrl = "/api/searchContent/BuildableContentProducts";

  columnHeader = {
    checkbox: '',
    fittingEntryID: 'FittingEntryID',
    fittingIDNumber: 'FittingIDNumber',
    productLine: 'ProductLine',
    manufacturer: 'Manufacturer',
    material: 'Material',
    category: 'Category',
    productType: 'Product Type',
    installType: 'Install Type',
    componentRelativePath: 'Component Relative Path',
  };
}
