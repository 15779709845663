/* tslint:disable */
export enum ActionTypeEnumDto {
  Blank = 'Blank',
  Add = 'Add',
  Delete = 'Delete',
  Update = 'Update',
  PartialSaveAdd = 'PartialSaveAdd',
  PartialSaveDelete = 'PartialSaveDelete',
  PartialSaveUpdate = 'PartialSaveUpdate',
  SendForApproveAdd = 'SendForApproveAdd',
  SendForApproveDeletedSize = 'SendForApproveDeletedSize',
  SendForApproveUpdatedSize = 'SendForApproveUpdatedSize',
  RejectedAddedSize = 'RejectedAddedSize',
  RejectedDeletedSize = 'RejectedDeletedSize',
  RejectedUpdatedSize = 'RejectedUpdatedSize',
  ApprovedAddedSize = 'ApprovedAddedSize',
  ApprovedDeletedSize = 'ApprovedDeletedSize',
  ApprovedUpdatedSize = 'ApprovedUpdatedSize',
  UndoAdd = 'UndoAdd',
  UndoDelete = 'UndoDelete',
  UndoUpdate = 'UndoUpdate',
  ChangeToContinue = 'ChangeToContinue',
  ChangeToDiscontinue = 'ChangeToDiscontinue',
  ChangeToContinueApproved = 'ChangeToContinueApproved',
  ChangeToDiscontinueApproved = 'ChangeToDiscontinueApproved',
  RemoveAddedAfterPartialSave = 'RemoveAddedAfterPartialSave',
  RemoveUpdatedAfterPartialSave = 'RemoveUpdatedAfterPartialSave',
  RemoveDeletedAfterPartialSave = 'RemoveDeletedAfterPartialSave',
  GetContinueDiscontinue = 'GetContinueDiscontinue',
  UndoRequestToContinue = 'UndoRequestToContinue',
  UndoRequestToDiscontinue = 'UndoRequestToDiscontinue',
  RequestToContinueFittingSendForApproval = 'RequestToContinueFittingSendForApproval',
  RequestToDiscontinueFittingSendForApproval = 'RequestToDiscontinueFittingSendForApproval',
  RequestToContinueFittingGotRejected = 'RequestToContinueFittingGotRejected',
  RequestToDiscontinueFittingGotRejected = 'RequestToDiscontinueFittingGotRejected'
}
